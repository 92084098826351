<template>
  <div class="creates-wrap">
    <div class="creates-box">
      <el-form
        ref="ruleForm"
        size="small"
        class="c-ruleform"
        label-position="left"
        label-width="100px"
        :model="ruleForm"
        :rules="rules"
      >
        <el-form-item label="所属企业" prop="customerName">
          <el-select
            style="width: 280px"
            filterable
            remote
            v-model="ruleForm.customerName"
            placeholder="企业名称/ID"
            :disabled="disableItemsInActiveState || disableItemsInInactiveState"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="handleCustomerName"
          >
            <el-option
              v-for="item in customeOptions"
              :key="item.customerCode"
              :label="item.customerName"
              :value="item.customerCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!editIdstatus" label="柜机数量" :prop="!editIdstatus ? 'lockerNum' : ''">
          <el-input placeholder="请输入柜机数量" v-model="ruleForm.lockerNum"></el-input>
        </el-form-item>
        <el-form-item label="产品类型" prop="productCode">
          <Select
            v-model="ruleForm.productCode"
            :disabled="disableItemsInActiveState || disableItemsInInactiveState"
            :list="productCodeList"
            @change="handlerCode"
          ></Select>
        </el-form-item>
        <el-form-item label="设备所属" prop="productCompany">
          <Select
            v-model="ruleForm.productCompany"
            :disabled="disableItemsInActiveState"
            :list="productCompanyList"
          ></Select>
        </el-form-item>
        <el-form-item label="烧录编码" prop="burnWay">
          <Select :disabled="disableItemsInActiveState" v-model="ruleForm.burnWay" :list="burnWayList"></Select>
        </el-form-item>
        <el-form-item label="室内/外柜">
          <Select v-model="ruleForm.lockerType" :list="lockerTypeList"></Select>
        </el-form-item>
        <el-form-item label="供应商">
          <Select v-model="ruleForm.manufactureCode" :list="manufactureCodeList" @change="handlerManufa" :disabled="disableItemsInPlat"> </Select>
        </el-form-item>
        <el-form-item label="柜机规格" prop="viceType">
          <Select v-model="ruleForm.viceType" :list="viceTypeList" @change="handlerViceType" :disabled="disableItemsInActiveState"></Select>
        </el-form-item>
        <el-form-item label="可视化模版" prop="lockerTemplateList">
          <el-button plain type="success" @click="choiceAdd" :disabled="disableItemsInActiveState">选择</el-button>
          <span style="color: #666666; margin-left: 12px">最多可关联{{ viceTypeLength }}个模版</span>
        </el-form-item>
        <div class="visual-wrap">
          <div class="visual-con" v-for="(item, index) in ruleForm.lockerTemplateList" :key="index">
            <el-input disabled v-model="item.lockerTemplateName"></el-input>
            <i class="el-icon-circle-close close-temp" :data-disabled="disableItemsInActiveState" @click="delectTemp(item)"> </i>
          </div>
        </div>
      </el-form>
    </div>
    <div class="creates-footer">
      <div class="c-ruleform-but">
        <el-button size="small" type="success" @click="submitForm('ruleForm')">确定</el-button>
        <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
      </div>
    </div>
    <Dialog width="920px" :title="'选择可视化'" :visible="visible" @open="openCallback" @close="closeDialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form
        size="small"
        label-width="120px"
        ref="ruleFormDevice"
        label-position="left"
        :model="formDevice"
        @submit.native.prevent
      >
        <el-form-item label="可视化模版：">
          <el-input
            style="width: 280px"
            placeholder="请输入模版ID/名称"
            v-model="formDevice.deviceTemplateLike"
            @change="queryDeviceTable"
          >
          </el-input>
          <el-button plain style="margin-left: 12px" type="success" native-type="submit" @click="queryDeviceTable"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <Table
        ref="tableRef"
        :loading="tableLoading"
        :hidden="!tableData.length"
        :header-cell-style="{ background: '#F7F8FA ' }"
        :columns="columns"
        :data="tableData"
        :total="pageParams.total"
        :page="pageParams.pageNum"
        :limit="pageParams.pageSize"
        row-key="deviceTemplateCode"
        @selection-change="handleSelectionChange"
        @pagination="updatePage"
      >
        <el-table-column slot="selection" type="selection" width="50" fixed="left"  :reserve-selection="true" />
        <template #deviceTemplateName="{ originScope }">
          <span class="g-single-overflow">{{ originScope.row.deviceTemplateName }}</span>
        </template>
        <template #productList="{ originScope }">
          <template v-if="originScope.row.productList.length">
            <div v-for="(item, index) in originScope.row.productList" :key="index">
              <span>{{ item.productCode }}</span>
            </div>
          </template>
          <div v-else>-</div>
        </template>
        <el-table-column slot="operate" label="操作" width="100">
          <template #default="scope">
            <el-button type="text" size="small" @click="handlePreview(scope.row)">预览</el-button>
          </template>
        </el-table-column>
      </Table>
      <el-row style="margin-top: 10px" type="flex" justify="end">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="success" @click="submitDevice" size="small">确认</el-button>
      </el-row>
    </Dialog>
    <PublicDiaTc
      :title="title"
      :device-template-code="deviceTemplateCode"
      :visible.sync="diaDeviceVisible"
      :status="cabstatus"
    >
    </PublicDiaTc>
  </div>
</template>

<script>
import Select from '@/components/Select'
import Table from '@/components/TablePage'
import Dialog from '@/components/Dialog'
import PublicDiaTc from '../../../components/public-dia-tc'
import { getVendorList, getEnterpriseList, searchWithStatic } from '@/api/modules/device'
import { createLocker, updateLocker, getLockerDetail } from '@/api/modules/cabinet'
export default {
  name: 'cabinetListCreates',
  components: {
    Table,
    Select,
    Dialog,
    PublicDiaTc
  },
  data() {
    const customerRule = (rule, value, callback) => {
      const rules = /^\d{1,}$/
      const minvalue = 1
      const maxvalue = 100
      if (!rules.test(value)) {
        callback(new Error('请输入数字'))
      }
      if (value < minvalue || value > maxvalue) {
        callback(new Error('请输入1-100的数字'))
      }
      callback()
    }
    return {
      ruleForm: {
        customerName: '',
        customerCode: '',
        lockerNum: '',
        productCode: '',
        productName: '',
        productCompany: '',
        burnWay: '',
        lockerType: '',
        manufactureCode: '',
        manufactureName: '',
        viceType: '',
        lockerTemplateList: []
      },
      rules: {
        customerName: [{ required: true, message: '请输入所属企业', trigger: 'blur' }],
        lockerNum: [
          { validator: customerRule, trigger: 'blur' },
          { required: true, message: '请输入柜机数量', trigger: 'blur' }
        ],
        productCode: [{ required: true, message: '请选择产品类型', trigger: 'change' }],
        productCompany: [{ required: true, message: '请选择设备所属', trigger: 'change' }],
        burnWay: [{ required: true, message: '请选择烧录编码', trigger: 'change' }],
        viceType: [{ required: true, message: '请选择柜机规格', trigger: 'change' }],
        lockerTemplateList: [
          // { validator: visualRule, trigger: 'change' },
          { required: true, message: '请添加可视化模版', trigger: 'change' }
        ]
      },
      editIdstatus: !!this.$route.query.id,
      visible: false,
      loading: false,
      tableLoading: false,
      customeOptions: [],
      columns: [
        { label: '模版ID', prop: 'deviceTemplateCode' },
        { label: '模版名称', prop: 'deviceTemplateName', width: 200 },
        { label: '主/副柜', prop: 'deviceMainSub' },
        { label: '适用内部型号', prop: 'productList' }
      ],
      tableData: [],
      productCodeList: [],
      productCompanyList: [
        { value: 1, label: '丰巢机' },
        { value: 2, label: '客户机器' }
      ],
      burnWayList: [
        { value: 1, label: '必须烧录' },
        { value: 2, label: '都可以' }
      ],
      manufactureCodeList: [],
      viceTypeList: [],
      lockerTypeList: [
        { value: 1, label: '室内机' },
        { value: 2, label: '室外机' }
      ],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      selectedRows: [],
      formDevice: {
        deviceTemplateLike: ''
      },
      deviceTemplateCode: '',
      diaDeviceVisible: false,
      cabstatus: 1,
      title: '',
      viceTypeLength: 0
    }
  },
  created() {
    this.getVendor('productCodeList', 'productType')
    this.getVendor('manufactureCodeList', 'supplier')
    this.getVendor('viceTypeList', 'LockerType')
  },
  mounted() {
    if (this.editIdstatus) {
      this.getList()
      // this.$route.matched[1].meta.title = '编辑柜机'
    }
  },
  computed: {
    // 1、IOT-柜机管理，编辑已激活柜机时（企业或系统创建的柜机），带*号均不可编辑，置灰；
    disableItemsInActiveState() {
      return this.ruleForm.lockerStatus === 2
    },
    // 2、IOT-柜机管理，编辑未激活柜机时，如果是企业平台创建时，【设备所属、烧录、室内外、柜机规格、可视化】字段可编辑，其它字段不可编辑置灰，IOT平台创建柜机所有字段均可编辑；
    disableItemsInInactiveState() {
      return this.ruleForm.lockerStatus !== 2 && this.ruleForm.createdPlatform === 1
    },
    // 3、企业平台创建供应商不可修改
    disableItemsInPlat() {
      return this.ruleForm.createdPlatform === 1
    }
  },
  methods: {
    // 获取参数
    getParams() {
      const getParam = { ...this.formDevice }
      getParam.pageNum = this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    submitForm(formName) {
      const list = this.ruleForm.lockerTemplateList || []
      // !list.length && this.$message.warning('请添加可视化模版')
      this.$refs[formName].validate((valid) => {
        if (!valid || !list.length) return
        const data = this.ruleForm
        // console.log('data: ', data)
        if (!this.editIdstatus) {
          this.createLocker(createLocker, data)
        } else {
          this.createLocker(updateLocker, data)
        }
      })
    },
    getList() {
      getLockerDetail({ lockerCode: this.$route.query.id }).then((res) => {
        // console.log('res: ', res)
        if (res.code === '000000000') {
          this.ruleForm = { ...this.ruleForm, ...res.data }
          this.ruleForm.lockerTemplateList = res.data.visualTemplateList || []
          this.ruleForm.viceType = this.ruleForm.viceType.toString()
          this.viceTypeLength = Number(this.ruleForm.viceType)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    createLocker(fn, data) {
      fn(data).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.$router.push('/cabinet-manage')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 选择可视化
    submitDevice(formName) {
      const arr = this.selectedRows?.map((item) => {
        return {
          lockerTemplateCode: item.deviceTemplateCode,
          lockerTemplateName: item.deviceTemplateName
          // deviceTemplateCode: item.deviceTemplateCode,
          // deviceTemplateName: item.deviceTemplateName
        }
      }) || []
      const codes = arr?.map(item => item.lockerTemplateCode) || []
      const filterList = this.ruleForm.lockerTemplateList?.filter(item => !codes.includes(item.lockerTemplateCode)) || []
      const selectedTemplateList = [...arr, ...filterList]
      if (!selectedTemplateList.length) {
        this.$message.warning('请先选择可视化内容添加')
        return false
      }
      if (this.ruleForm.viceType && selectedTemplateList.length > this.viceTypeLength) {
        this.$message.warning(`最多可关联${this.viceTypeLength}个可视化模版`)
        return false
      }

      this.ruleForm.lockerTemplateList = selectedTemplateList
      this.visible = false
      return null
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$router.go(-1)
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const data = {
            pageNum: 1,
            pageSize: 9999,
            customerName: query
          }
          getEnterpriseList(data).then((res) => {
            console.log('res: ', res)
            this.customeOptions = res.data.records || []
          })
        }, 150)
      } else {
        this.customeOptions = []
      }
    },
    handleCustomerName(val) {
      this.ruleForm.customerCode = val
      this.ruleForm.customerName = this.customeOptions.reduce((t, v) => {
        if (v.customerCode === val && v.customerName) {
          t = v
        }
        return t.customerName || t
      }, '')
    },
    // 查询可视化table
    queryDeviceTable() {
      // if (!this.formDevice.deviceTemplateLike) {
      //   this.$message.warning('请先输入可视化模版内容选中并查询')
      // }
      this.tableLoading = true
      searchWithStatic({ ...this.getParams() })
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
          const lockerTemplateList = this.ruleForm.lockerTemplateList || []
          if (lockerTemplateList.length && records.length) {
            const tempList = []
            lockerTemplateList.forEach((item) => tempList.push(item.lockerTemplateCode))
            records.forEach((item) => {
              const rows = this.selectedRows.map(itm => itm.deviceTemplateCode)
              if (tempList.includes(item.deviceTemplateCode) && this.$refs.tableRef && !rows.includes(item.deviceTemplateCode)) {
                this.$nextTick(() => {
                  this.$refs.tableRef.$refs.tableRef.toggleRowSelection(item)
                })
              }
            })
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    // 获取下拉列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    // 预览
    handlePreview(row) {
      // console.log('handlePreview: ', row)
      this.deviceTemplateCode = row.deviceTemplateCode
      this.diaDeviceVisible = true
      this.title = `${row.deviceTemplateName}(${row.deviceTemplateCode})`
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.queryDeviceTable()
      }
    },
    // 删除模版项
    delectTemp(item) {
      console.log('delectTemp: ', item)
      const lockerTemplateList = this.ruleForm.lockerTemplateList
      if (lockerTemplateList.length) {
        const index = this.ruleForm.lockerTemplateList.indexOf(item)
        if (index !== -1) {
          this.$confirm('确认要删除吗').then(() => {
            this.ruleForm.lockerTemplateList.splice(index, 1)
            this.$forceUpdate()
          })
        }
      }
    },
    // 下拉（处理产品类型）
    handlerCode(val) {
      const list = this.productCodeList
      this.ruleForm.productName = list.reduce((t, v) => (v.itemCode === val ? [...t, v.itemValue] : t), []).toString()
    },
    handlerManufa(val) {
      const list = this.manufactureCodeList || []
      this.ruleForm.manufactureName = list.reduce((t, v) => (v.value === val ? [...t, v.label] : t), []).toString()
    },
    handlerViceType(val) {
      this.viceTypeLength = Number(val)
    },
    choiceAdd() {
      if (!this.ruleForm.viceType) {
        this.$message.warning('请先选择柜机规格')
        return
      }
      this.visible = true
    },
    handleSelectionChange(selection) {
      console.log(selection, 'handleSelectionChangehandleSelectionChange')
      this.selectedRows = selection
    },
    closeDialog() {
      console.log('closeDialog: ')
      this.visible = false
      this.tableData = []
      this.formDevice.deviceTemplateLike = ''
      this.$refs.tableRef.$refs.tableRef.clearSelection()
    },
    openCallback() {
      this.queryDeviceTable()
    }
  }
}
</script>

<style lang="scss" scoped>
.creates-wrap {
  position: relative;
  min-height: calc(100vh - 118px);
}
.creates-box {
  padding: 30px 0 0 120px;
  box-sizing: border-box;
  position: relative;
  .c-ruleform {
    width: 380px;
  }
  .visual-wrap::-webkit-scrollbar {
    display: none;
  }
  .visual-wrap {
    overflow-y: scroll;
    height: 200px;
    padding-left: 100px;
  }
  .el-autocomplete {
    width: 280px;
  }
  .custom-autocomplete {
    li {
      line-height: normal;
      padding: 7px;
      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        color: #b4b4b4;
      }
      .highlighted .name {
        color: #ddd;
      }
    }
  }
}
.creates-footer {
  border-top: 2px solid #ebedf0;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 100%;
  background: #ffffff;
  .c-ruleform-but {
    // position: relative;
    // bottom: 20px;
    // left: 45%;
    display: flex;
    justify-content: center;
  }
}
.visual-con {
  margin-top: 14px;
  display: flex;
  // align-items: center;
}
.close-temp {
  color: #a2a3a5;
  font-size: 25px;
  margin: 4px 0 0 10px;
  &[data-disabled="true"]{
    pointer-events: none;
  }
}
.el-form-item--small {
  margin-bottom: 20px;
}
</style>
