import { axios1 } from '../instance'

/* 柜机管理 */

// 产品类型

/**
 * 产品搜索
 * @param {object} data
 * @param {string} data.productType 类型
 * @param {string} data.productCode 编码
 * @param {string} data.pageNum  页码
 * @param {string} data.pageSize  页数
 * @return {Promise}
 */
export const getProductList = data => {
  return axios1.postByJson('/product/list', data)
}

/**
 * 产品创建
 * @param {object} data
 * @param {string} data.productCode 编码
 * @param {string} data.authenticationMode 认证模式
 * @param {string} data.productType  产品类型
 * @param {string} data.encryptType  加密方式
 * @return {Promise}
 */
export const createProduct = data => {
  return axios1.postByJson('/product/create', data)
}

/**
 * 产品编辑
 * @param {object} data
 * @param {string} data.productCode 编码
 * @param {string} data.authenticationMode 认证模式
 * @param {string} data.productType  产品类型
 * @param {string} data.encryptType  加密方式
 * @return {Promise}
 */
export const updateProduct = data => {
  return axios1.postByJson('/product/update', data)
}

/**
 * 产品删除
 * @param {object} data
 * @param {string} data.productCode 编码
 * @return {Promise}
 */
export const deleteProduct = data => {
  return axios1.postByJson('/product/delete', data)
}

// 柜机列表

/**
 * 柜机列表
 * @param {object} data
 * @param {string} data.customerCode 企业ID
 * @param {string} data.customerName 企业名称
 * @param {string} data.country 国家
 * @param {string} data.createUser 创建人
 * @param {string} data.lockerCode 柜机编码
 * @param {Array} data.lockerCodeList 柜机编码列表
 * @param {number} data.lockerStatus  柜机状态 1未使用，2使用中 3 维护中 4、弃用
 * @param {string} data.productName  产品类型
 * @param {number} data.queryTimeType  查询类型 1: 激活时间，2:创建时间
 * @param {number} data.signalStatus  信号状态 0离线，1在线
 * @param {string} data.viceType  规格（一拖几）（-1.主柜不存在副柜存在 0.零拖零 1.一拖零 2.一拖一 3.一拖二 4.一拖三 5.一拖四 6.一拖五 7.一拖六 8.一拖七 9.一拖八 10.一拖九 11 一拖十 12 一拖十一 13 一拖十二)
 * @param {string} data.startTime  查询开始时间
 * @param {string} data.endTime 查询结束时间
 * @param {string} data.pageNum  页码
 * @param {string} data.pageSize  页数
 * @return {Promise}
 */
export const getpageLockerList = data => {
  return axios1.postByJson('/locker/pageLockerList', data)
}

/**
 * 柜机新增
 * @param {object} data
 * @param {string} data.companyCode  企业编码
 * @param {string} data.companyName  企业名称
 * @param {string} data.lockerNum  柜机数量
 * @param {string} data.lockerType   柜机类型(1:室内机,2:户外机)
 * @param {string} data.manufactureName   供应商
 * @param {string} data.productName   产品类型
 * @param {string} data.viceType
 * @param {string} data.lockerTemplateList 可视化模板Code列表
 * @return {Promise}
 */
export const createLocker = data => {
  return axios1.postByJson('/locker/create', data)
}

/**
 * 柜机修改
 * @param {object} data
 * @param {string} data.companyCode  企业编码
 * @param {string} data.lockerNum  柜机数量
 * @param {string} data.lockerType   柜机类型(1:室内机,2:户外机)
 * @param {string} data.manufactureName   供应商
 * @param {string} data.productName   产品类型
 * @param {string} data.viceType
 * @param {string} data.visualTemplateCodeList 可视化模板Code列表
 * @return {Promise}
 */
export const updateLocker = data => {
  return axios1.postByJson('/locker/update', data)
}

/**
 * 柜机详情
 * @param {string} lockerCode 柜机编码
 * @return {Promise}
 */
export const getLockerDetail = (params) => {
  return axios1.get('/locker/getLockerDetail', { params })
}

/**
 * 柜机删除
 * @param {Array} lockerCodeList 柜机编码列表
 * @return {Promise}
 */
export const removeLocker = (data) => {
  return axios1.postByJson('/locker/remove', data)
}